    .back {
        position: absolute;
        border: none;
        border-radius: 10px;
        width: 4rem;
        height: 30px;
        top: -4px;
        /* color: white; */
        background: gray;

        a {
            color: white;
        }

        a:hover {
            color: white;
            text-decoration: none;
        }
    }

    .property-manage {
        display: flex;
        margin-right: 15px;
        margin-bottom: 10px;

        .page-title {
            width: 35%;
            align-self: center;
            margin-top: 10px;

            .page-heading {
                margin-bottom: 15px;
                margin-top: 15px;
            }
        }

        .property-info-table {
            width: 65%;

            .property-details {
                .react-bootstrap-table {
                    margin: 0;
                }

                &>div {
                    button {
                        position: absolute;
                        top: -15px;
                        right: 20px;
                    }
                }
            }
        }

        .property-details {
            .property-info {
                float: right;
                text-align: right;
                font-size: 12px;
                font-weight: 600;

                >div {
                    line-height: 12px;
                }

                .green {
                    font-size: 15px;
                    color: green;
                    margin-top: 5px;
                }
                .red {
                    font-size: 15px;
                    color: red;
                    margin-top: 5px;
                }
            }
        }


    }