.property-upload {
    display: flex;
    justify-content: space-between;
}

.property-upload .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.import-assoc-file {
    display: flex;
}

.import-assoc-file input {
        height: 40px !important;
        padding: 4px;
        border-radius: none;
    }

.import-assoc-file  button {
        border-radius: 10px;
        border: none;
        background: gray;
        color: white;
        margin-left: 10px;
    }

.template {
	color: blue!important;
}