.email-access-general-info {
  .page-header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    min-height: 70px;
    padding: 0.5rem 2rem ;
    z-index: 20;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  }
  .property-data {
    margin-top: 75px;
    padding: 1rem 2rem;
    text-align: right;
  }
  .general-component{
      padding: 0rem 1rem;
        .listdiv .lease-info .buttons .update-btn {
            position: absolute;
            top: 280px;
            right: 2%;
        }
        .reduce-margin{
            margin: 0px;
        }
        .general-info-tab{
            border: none !important;
        }
        .general-info-tab li.nav-item {
            background-color: darkgrey;
            min-width: 14.1%;
            text-align: center;
        
            &.active {
                margin-top: -0.5%;
                background-color: lightblue;
                border: none;
            }
        
            .nav-link {
                color: black;
            }
        }
        .general-info-tab li.nav-item .nav-link.active {
            background-color: lightblue;
            border-color: lightblue;
            border: none !important;
        }
        
      }
}
