.content {
    margin-top: 100px;
    margin-left: 18%;
}

.content .content-title {
    padding: 10px 0px 0px 0px;
    position: relative;
}

.content .content-title .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.content .content-title .page-heading {
    margin-bottom: 15px;
}

.content .content-title .search-btn {
    margin: auto !important;
}