.quick-stats{
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid lightblue;
    .heading{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 15px;
    }
    .section{
        display: flex;
        padding-bottom: 10px;
        .properties{
            width: 18%;
        }
        .properties-av{
            width: 20%;
            border-left: 1px solid lightblue;
            border-right: 1px solid lightblue;
            text-align: center;
        }
        .state-filter{
            width: 60%;
            .dropdown{
                padding: 15px;
                width: 75%;
                margin: 0px auto;
                .states-select{
                    width: 100%;
                }
            }

            .results{
                display: flex;
                justify-content: space-around;
            }
        }
    }

    .key-value{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .key{
            font-size: 16px;
            font-weight: 600;
        }
        .value{
            font-size: 20px;
            font-weight: bold;
        }
    }
}