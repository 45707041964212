.header {
    height: 72px;
    padding: 0 2rem;
    z-index: 20;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .15);
}

.header::after {
    display: block;
    clear: both;
    content: "";
}

.header .header-logo {
    position: relative;
    background: white;
    background-position: center;
}

.header .user-div {
    height: 58px;
    display: flex;
    position: absolute;
    right: 0;
    color: black;
}

.header .user-div .users-sub-div p {
    font-weight: bold;
}

.header .user-div .userimg img {
    height: 50px;
    cursor: pointer;
}



.header .user-div .users-sub-div .logout-btn {
    cursor: pointer;
}