.per-page {
    display: flex;
    text-align: right;
    width: 43%;
    justify-content: flex-start;
        align-items: baseline;
            margin-left: 4%;
}

.page-no {
    display: flex;
    text-align: right;
    width: 43%;
    justify-content: flex-end;
        align-items: baseline;
}

.page-wrapper {
	    display: flex;
    justify-content: space-between;
}

.hidden {
    display: none;
}