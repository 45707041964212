.inspections {
    &-info {
        width: 55%;

        button {
            float: right;
            position: relative;
            bottom: 45px;
            left: 15%;
            border-radius: 4px;
            width: 70px;
            height: 35px;
            border: none;
            color: white;
            background: gray;
        }
    }

    &-intervals {
        .intervals-info {
            display: flex;
            width: 100%;
            padding: 15px;

            button {
                position: relative;
                left: 2%;
                top: 5px;
                border-radius: 4px;
                width: 70px;
                height: 35px;
                border: none;
                color: white;
                background: gray;
            }

            .heading {
                padding: 10px;
                width: 25%;
            }

            .select-box {
                width: 30%;
            }
        }
    }
}