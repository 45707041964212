.import-modal {
  .import-file {
    display: flex;

    input {
      height: 40px;
      padding: 4px;
      border-radius: none;
    }

    button {
      border-radius: 10px;
      border: none;
      background: gray;
      color: white;
      margin-left: 10px;
    }
  }
}

.modal-dialog.status-modal{
    min-width: 60%;
    .clientid-width{
        width: 2%;
    }
    .status-width{
        width: 2%;
    }
    .upload-status{
      .status{
        display: flex;
        justify-content: space-between;
      }
    }
}
