@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.login-container {}

.login-container .login-div {
    justify-content: center;
    margin-top: 15%;
}

.login-container .login-div .login-form {
    width: 40%;
}
input {
    padding: 15px;
    border-radius: 12px !important;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 25px #ffffff inset;
}
.btn {
    border-radius: 12px !important;
}
  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 15px;
    position: fixed;
  margin-top: 20%;
  }
  .wrap-login100 {
    width: 410px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 37px 55px;
    background: #2356a4;
    background: -webkit-linear-gradient(top, #525352,#c1c1c178);
    margin-top: -60%;   
    color: #fff;
  }
  .login100-form-logo {
    font-size: 60px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 162px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
  }
  .wrap-input100 {
    width: 100%;
    position: relative;
    /* border-bottom: 2px solid rgba(255,255,255,0.24); */
    margin-bottom: 35px;
  }
  .input100 {
    font-size: 16px;
    color:white;
    font-weight: 600;
    line-height: 1;
    display: block;
    width: 100%;
    background: transparent;
    margin-left: 9%;
    height: 45px;
    margin-top: 10%;
    padding: 0 5px 0 15px;
    border: 1px solid whitesmoke;
    margin-left: 1%;
  }
  ::placeholder {
    color: white;
  }

  .container-login100-form-btn {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 27%;
  }
  .login100-form-btn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #555555;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    position: relative;
    z-index: 1;
    margin-top: -11%;
    border-style:none ;
  }
  .login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: white;
  }

  