.general-info-tabcol {
    display: flex;
    flex-direction: column;
    .listCompButton{
        float: left;
        width: 100%;
        button {
            float: left;
        }
    }

    .details {
        padding: 10px;
        width: 100%;

        .message {
            text-align: center;
            margin-top: 24px;
        }

        .heading {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: crimson;
            margin-bottom: 20px;
        }

        .buttons {
            display: flex;
            margin-bottom: 10px;

            button {
                border-radius: 4px;
                width: 70px;
                height: 35px;
                border: none;
            }

            .update-btn button {
                background: #0e7bd8;
                color: white;
            }

            .delete-btn button {
                color: white;
                background: #ea2f2f;
            }
        }

        .edit-btn {
            border-radius: 4px;
            width: 70px;
            height: 35px;
            border: none;
            background-color: gray;
        }

        .info {
            padding: 5px 5px 5px 5px;
            display: flex;

            .name {
                font-size: 12px;
                font-weight: 600;
            }

            .value {
                font-size: 14px;
                margin-left: 5px;
            }
        }

        .create-btn {
            position: relative;
            float: right;
            left: -5%;

            button {
                border-radius: 4px;
                width: 70px;
                height: 35px;
                border: none;
                color: white;
                background: #118709;
            }
        }

        .accesscodes {
            border-bottom: 4px solid powderblue;

            .buttons {
                display: flex;
                margin-bottom: 10px;

                .create-btn {
                    margin-right: 5px;
                    position: absolute;
                    top: 20px;
                    right: 30px;
                }
            }
        }

        .lease-info {
            border-bottom: 4px solid powderblue;

            .buttons {
                display: flex;
                margin-bottom: 10px;

                .create-btn {
                    margin-right: 5px;
                    position: absolute;
                    top: 20px;
                    right: 30px;
                }
            }
        }

        .property-hoa-list {
            border-bottom: 4px solid powderblue;
        }
    }
}

.listCompButton {
    position: relative;
    float: right;
    margin-top: -1%;
    margin-right: 2%;
    width: 65%;

    button {
        float: right;
    }
}

.addMarginBottom {
    margin-bottom: 1%;
}
.paginationPageSelect{/*
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;*/
    width: 22%!important;
    margin-right: 5%;
    margin-left: 4%;
}

