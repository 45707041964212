.listdiv {
    .lease-info {
        .buttons {
            .update-btn {
                position: absolute;
                top: 243px;
                right: 2%;
            }
        }
    }
}
.lease_info_modal.modal-dialog{
    min-width: 50% !important;
    .modal-body{
        padding-left: 20px;
    }
}