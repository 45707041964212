body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.react-bootstrap-table .table {
    table-layout: auto !important;
}

input {
    width: 100%;
    border: 1px solid lightgray;
    height: 36px;
    border-radius: 6px;
}

.createBtn {
    float: right;
    margin-right: 10px;
}

.hide {
    display: none !important;
}

.loader-img-div {
    position: fixed;
    top: 50%;
    left: 50%;
}

.loader-img-div>img {
    height: 50px;
}

.charges-header {
    display: flex;
    justify-content: space-between;
}

.center-align{
    text-align: center;
}

.font-awesome-enaled {
    text-align: center;
    cursor: pointer;
    line-height: normal;
    margin-right: 4%;
}
.font-awesome-disabled{
    color: red;
    text-align: right;
    cursor: not-allowed;
    line-height: normal;
    margin-left: 25%;
}
.property-hoa-select{
    margin-bottom: 10px;
}


input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}