.code {
    margin-bottom: 20px;
}

.aco {
    border: 1px solid #aaa;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
}
.questions {
    .question {
        margin: 10px;
    }

    .radio-buttons {
        display: flex;
    }
}

.access-codes-container {
    width: 50%;
}
.custom-control {
    margin-right: 10px;
    margin-left: 10px;
}

.access-code-modal.modal-dialog {
    max-width: 85%;

    .modal-content {
        width: 100%;
        padding-left: 0%;
    }


    .control-details {
        padding: 10px;
    }

    .yes-no-div {
        display: block;
        margin-bottom: 10px;

        .yes-no {
            display: flex;
        }
    }

    .access-form {
        position: relative;
        bottom: 60px;
        width: 45%;
        left: 20%;
    }

    .create-Btn {
        position: absolute;
        bottom: 16px;
        left: 125px;
    }
}