.form-date {
    float: left;
    margin-top: -10%;
}
.to-date {
    float: right;
    margin-top: -6%;
    margin-right:50%
}

.react-bootstrap-table {
    margin-top: 4%;
}