.page {
    display: flex;
    margin-top: 72px;
    height: -webkit-fill-available;
}

.page .sidebar {
    width: 15%;
    background-color: lightgray;
}

.page .page-content {
    width: 85%;
}

/* .history {
     display: none;
} */