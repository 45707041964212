.tenantForm {

    input[type="text"],
    input[type="number"] {
        width: 60%;
    }

    label {
        margin-right: 3%;
    }



    .styled,
    input[type="radio"] {
        appearance: none;
        margin: 0 2%;
        width: 12px;
        height: 12px;
        background: #eeeeee;
        box-shadow:
            inset 0 0 0 .4em white,
            0 0 0 .1em;
        border-radius: 50%;
        transition: .2s;
        cursor: pointer;
        color: #363945;

        &:hover,
        &:checked {
            background: #363945;
            box-shadow:
                inset 0 0 0 .6em white,
                0 0 0 .2em;
        }

        &:checked {
            background: #56be8e;
            box-shadow:
                inset 0 0 0 .4em white,
                0 0 0 .3em #56be8e;
        }

        &:focus {
            outline: 0;
        }
    }
}