.tab-content {
    padding: 20px 0px 0px 0px;
}
.nav-tabs {
    border-bottom: none !important;
}

.tab li.nav-item {
    background-color: darkgrey;
    min-width: 15.6%;
    text-align: center;

    &.active {
        margin-top: -0.5%;
        background-color: lightblue;
        border: none;
    }

    .nav-link {
        color: black;
        display: block;
        padding: 0.5rem 0.5rem;
    }

}

.tab li.nav-item .nav-link.active {
    background-color: lightblue;
    border-color: lightblue;
}


.general-info-tab {
    border-bottom: 2px solid #dee2e6 !important;

    .general-info-tabnavitem {
        margin-bottom: -2px !important;
       
        cursor: pointer;
        .general-info-tabnavlink.active {
            color: #495057;
            background-color: #fff;
            border-bottom: 4px solid lightblue;
            border-top: none;
            border-right: none;
            border-left: none;
            
        }
         .nav-link {
            color: black;
            display: block;
            padding: 0.5rem 0.5rem;
        }
    
    }
}

.modalTabs {
    margin-left: 2%;
    


    .nav-item {
        width: 45%;
        text-align: center;
        
        
        &.active {
            margin-top: -0.5%;
            background-color: lightblue;
            border: none;
           
        
        }
        
    }
    
}

.general-info-tabpage-content {

    padding: 20px 10px 0px 0px;
}
