thead {
    tr {
        th {
            width: 10%;
        }
    }
}

.payment-modal.modal-dialog {
    max-width: 98%;
    .modal-content{
        .modal-body{
            padding-left: 0%;
            margin-bottom: -2%;
            .hide-button{
                display: none;
            }
        }
    }
}

.payment-section {
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -35px;

    .payment-form {
        width: 100%;
        padding: 10px;        

    .memo-edit-icon {
        position: absolute;
        top: 10%;
        left: -10%;
        cursor: pointer;
    }
        .heading{
           // text-align: center;
            font-weight: bold;
            padding: 10px;
           // text-align: center;
           // margin-bottom: 20px;
            margin-top: -10px;
            margin-left: -1%;
        }
        .bold-text-div{
            font-size: 12px;
            font-weight: bold;
        }
    }

    

    .payment-documents {

        width: 50%;
        padding: 10px;
        .heading{
            //text-align: center;
            font-weight: bold;
            padding: 10px;
            //text-align: center;
            //margin-bottom: 20px;
            margin-top: 10px;
            margin-left: -1%;
        }
    }

}

.other-payment-section {
    padding: 15px;
}

.charges-section{
    .charges-payment-data{
        display: flex;
        justify-content: space-between;
        .charges-payment-data-left{
            width: 40%;
            padding: 10px;
        }
        .charges-payment-data-right{
            width: 50%;
            padding: 10px;
        }
    }
    .payment-rejected{
        padding: 0px 10px 0px 10px;
        margin-top: -1%;
    }
    .payment-comments{
        .status-comment{
            margin-right: 5px;
            margin-left: 5px;
        }
    }
    .approval-comment{
        margin-top: 2%;
    }
    .charges-header{
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        margin-top: -40px;

        &>.charges-hoas {
            width: 40%;
            display: flex;
        }
        &>.charges-hoas Label {
            padding: 5px;
        }
        &>.charges-date {
            width: 30%;
            display: flex;
            margin-left: 10px;
        }
        &>.charges-date Label {
            padding: 5px;
        }
        &>.add-charge button {
            margin: 0;
            width: 200%;
        }
        &>.add-charge {
            margin-left: 10px;
        }      
    }  
}

.payment-status-label{
    width: 7%;
    padding-left: 1.5%; 
    font-size: 86%;
}

.checkNumber{
    width:5%;
    padding-left: 0.5%;
    font-size: 86%; 
}
.settlementDate{
    width:6%;
    padding-left: 0.5%;
    font-size: 86%;  
    
}
.settlementStatus{
    width: 6%;
    padding-left: 0.5%; 
    font-size: 86%;  
}

.reason{
    width:4%;
    padding-left: 0.5%; 
    font-size: 86%;  
}

.tracking{
    width:9%; 
    padding-left: 1.5%;        
}
.documentName-label{
    width: 13%;
    padding-left: 1.5%; 
}

.payment-approved-input
{
    width:6%;
}
.settlementStatus-inputField{
    width: 15%;
    padding-left: 0.5%; 
}
.reason-inputField{
    width:14%;
    padding-left: 1%;
}
.tracking-inputField{
    width: 28%; 
    padding-left: 1.5%; 
}

.documentNotes-label{
    width: 11%;
    padding-left: 10px;
}
.documentNotes-inputField{
    width: 24%;
    padding-left: 1.5%; 
}

.documentName-inputField{
    padding-left: 0%;
    width: 18%;
}

.payment-status-input{
    width: 9%;
    //padding-left: 1.5%; 
    
}
.payment-information-input{
    width: 10%;
    padding-left: 0.5%;
}
.payment-information-label{
    width: 180px;
    padding-left: 1.5%;
}

.payment-dueDate-label{
    padding-left: 0.5%;
    width: 13%;
}

.invoice-number-label{
    width: 5%;
    padding-left: 0.5%;
}

.invoice-received-label{
    width: 180px;
    padding-left: 0.5%;
    
}

.invoice-number-input{
    width: 23%;
    padding-left: 0.5%;
}

.heading{
    margin-top: -20px;
    font-weight: bold;
    padding: 10px;
    //margin-bottom: 20px;
}

.payment-form-group{
    margin-top: -0.5%;
    .payment-div{
        &-inv{
            display: inline-flex;
            width: 25%;
            padding: 10px;
            &-label{
                // width: 45%;
                margin-right: 10px;
            }
        }
        &-rec{
            display: inline-flex;
            width: 25%;
            padding: 10px;
            &-label{
                // width: 45%;
                margin-right: 10px;
            }  
        }
        &-due{
            display: inline-flex;
            width: 25%;
            padding: 10px;
            &-label{
                // width: 50%;
                margin-right: 10px;
            }
        }
        &-num{
            display: inline-flex;
            width: 25%;
            padding: 10px;
            &-label{
                // width: 50%;
                margin-right: 10px;
            }
        }
        &-date{
            width: 125px;
        }
        &-input{
            width: 50%;
        }
    }

    @media (max-width:1250px){
        .payment-div{
            &-inv{
                display: inline-flex;
                width: 25%;
                padding: 6px;
                &-label{
                    // width: 45%;
                    margin-right: 6px;
                }
            }
            &-rec{
                display: inline-flex;
                width: 25%;
                padding: 6px;
                &-label{
                    // width: 45%;
                    margin-right: 6px;
                }  
            }
            &-due{
                display: inline-flex;
                width: 25%;
                padding: 6px;
                &-label{
                    // width: 50%;
                    margin-right: 6px;
                }
            }
            &-num{
                display: inline-flex;
                width: 25%;
                padding: 6px;
                &-label{
                    // width: 50%;
                    margin-right: 6px;
                }
            }
            &-date{
                width: 125px;
            }
            &-input{
                width: 50%;
            }
        }
    }
    
}

.payment-info{
    margin-right: 5px;
    margin-left: 10px;
    display: flex;
    .question{
        display: inline-flex;
        width: 20%;
        justify-content: flex-end;
        .radio{
            display: inline-flex;
            .custom-control{
                margin-right: 0px;
            }
        }
        .text{
            // width: 42%;
            text-align: end;
        }
    }
    .payee{
        display: inline-flex;
        width: 42%;
        justify-content: flex-end;
        label{
            margin-right: 10px;
        }
        .select-box{
            width: 400px;
        }
    }
    .other-payee{
        display: inline-flex;
        width: 42%;
        margin-left: 10px;
        justify-content: flex-end;
        label{
            margin-right: 10px;
            width: 160px;
            text-align: end;
        }
        .select-box{
            width: 400px;
        }
    }
    .memo{
        width: 35%;
        justify-content: flex-end;
        display: inline-flex;
        &-head{
            font-weight: 600;
            padding-right: 10px;
            width: 180px;
            text-align: end;
        }
        &-details{
            width: 20em;
        }
    }
}

.settlement-select{
    height: 35;
}

.paymentTable{
    margin-top: -2%;
}

.documentClass{
    padding-left: 2%;
    margin-bottom: 1%;
}

.checkNumber-input{
    width: 12%;
    font-size: 90%;
}

.documentUploadLabel{
    width: 15%;
    padding-left: 21px;
}
.documentUploadInput{
    width:18%
}

.documentTab{
    width: 50%;
}

.noDocument{
    margin-top: 20px;
    margin-left: 10px;
}

.other-acc{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    .acc{
        display: flex;
        .text{
            width: 19em;
        }
    }
    label{
        margin-right: 10px;
    }
}
