.graph-data{
    padding-top: 20px;
    .form{
        margin-left: 10%;
    }
}
.reduce-width{
    width: 10% !important;
    padding: 0.5 rem;
}
.notes-width{
    width: 80% !important;
}
.address-width{
    width: 15%;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret,
.react-bootstrap-table th .order > .dropdown > .caret,
.react-bootstrap-table th .order > .dropup > .caret {
  margin-bottom: 0;
  margin-top: 0;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .dropup .caret {
    border-bottom: 4px dashed;
    border-top: 0;
    content: '';
  }