.hoa-details {
    .increase-width{
        width: 40%;
    }
    .mutli-select {
        .hoa-multi-select {
            display: flex;
            float: right;
            margin-bottom: 10px;
            width: 100%;

            .select-box {
                width: 75%;
            }

            .button {
                padding: 7px;

                button {
                    border-radius: 4px;
                    width: 70px;
                    height: 35px;
                    border: none;
                    color: white;
                    background: gray;
                }
            }
        }

    }
}

.create-hoa {
        width: 130px;
}

.edit-link {
    cursor: pointer;
}

.acc-number{
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    margin-top: 2%;
}
.hoa_website {
    display: flex;
    overflow-wrap: break-word;
}
.hoa_site {
    width: 30%;
    font-weight: bold;
}
.assesment_description {
    width: 30%;
    font-weight: bold;
}
.payment_site {
    width: 30%;
    font-weight: bold;
}
.account_name {
    width: 30%;
    font-weight: bold;
}
.account_password {
    width: 30%;
    font-weight: bold;
}
.address {
    margin-top: 2%;
}