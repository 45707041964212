@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.react-bootstrap-table .table {
    table-layout: auto !important;
}

input {
    width: 100%;
    border: 1px solid lightgray;
    height: 36px;
    border-radius: 6px;
}

.createBtn {
    float: right;
    margin-right: 10px;
}

.hide {
    display: none !important;
}

.loader-img-div {
    position: fixed;
    top: 50%;
    left: 50%;
}

.loader-img-div>img {
    height: 50px;
}

.charges-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.center-align{
    text-align: center;
}

.font-awesome-enaled {
    text-align: center;
    cursor: pointer;
    line-height: normal;
    margin-right: 4%;
}
.font-awesome-disabled{
    color: red;
    text-align: right;
    cursor: not-allowed;
    line-height: normal;
    margin-left: 25%;
}
.property-hoa-select{
    margin-bottom: 10px;
}


input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}
.header {
    height: 72px;
    padding: 0 2rem;
    z-index: 20;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .15);
}

.header::after {
    display: block;
    clear: both;
    content: "";
}

.header .header-logo {
    position: relative;
    background: white;
    background-position: center;
}

.header .user-div {
    height: 58px;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    right: 0;
    color: black;
}

.header .user-div .users-sub-div p {
    font-weight: bold;
}

.header .user-div .userimg img {
    height: 50px;
    cursor: pointer;
}



.header .user-div .users-sub-div .logout-btn {
    cursor: pointer;
}
.login-container {}

.login-container .login-div {
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 15%;
}

.login-container .login-div .login-form {
    width: 40%;
}
input {
    padding: 15px;
    border-radius: 12px !important;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 25px #ffffff inset;
}
.btn {
    border-radius: 12px !important;
}
  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px;
    position: fixed;
  margin-top: 20%;
  }
  .wrap-login100 {
    width: 410px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 37px 55px;
    background: #2356a4;
    background: -webkit-linear-gradient(top, #525352,#c1c1c178);
    margin-top: -60%;   
    color: #fff;
  }
  .login100-form-logo {
    font-size: 60px;
    color:white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 159px;
    height: 162px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
  }
  .wrap-input100 {
    width: 100%;
    position: relative;
    /* border-bottom: 2px solid rgba(255,255,255,0.24); */
    margin-bottom: 35px;
  }
  .input100 {
    font-size: 16px;
    color:white;
    font-weight: 600;
    line-height: 1;
    display: block;
    width: 100%;
    background: transparent;
    margin-left: 9%;
    height: 45px;
    margin-top: 10%;
    padding: 0 5px 0 15px;
    border: 1px solid whitesmoke;
    margin-left: 1%;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
  ::-ms-input-placeholder {
    color: white;
  }
  ::placeholder {
    color: white;
  }

  .container-login100-form-btn {
    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 27%;
  }
  .login100-form-btn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #555555;
    line-height: 1.2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    position: relative;
    z-index: 1;
    margin-top: -11%;
    border-style:none ;
  }
  .login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: white;
  }

  
.property-upload {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.property-upload .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.import-assoc-file {
    display: -webkit-flex;
    display: flex;
}

.import-assoc-file input {
        height: 40px !important;
        padding: 4px;
        border-radius: none;
    }

.import-assoc-file  button {
        border-radius: 10px;
        border: none;
        background: gray;
        color: white;
        margin-left: 10px;
    }

.template {
	color: blue!important;
}
.content {
    margin-top: 100px;
    margin-left: 18%;
}

.content .content-title {
    padding: 10px 0px 0px 0px;
    position: relative;
}

.content .content-title .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.content .content-title .page-heading {
    margin-bottom: 15px;
}

.content .content-title .search-btn {
    margin: auto !important;
}
.per-page {
    display: -webkit-flex;
    display: flex;
    text-align: right;
    width: 43%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
        -webkit-align-items: baseline;
                align-items: baseline;
            margin-left: 4%;
}

.page-no {
    display: -webkit-flex;
    display: flex;
    text-align: right;
    width: 43%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
        -webkit-align-items: baseline;
                align-items: baseline;
}

.page-wrapper {
	    display: -webkit-flex;
	    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.hidden {
    display: none;
}
.import-modal .import-file {
  display: -webkit-flex;
  display: flex; }
  .import-modal .import-file input {
    height: 40px;
    padding: 4px;
    border-radius: none; }
  .import-modal .import-file button {
    border-radius: 10px;
    border: none;
    background: gray;
    color: white;
    margin-left: 10px; }

.modal-dialog.status-modal {
  min-width: 60%; }
  .modal-dialog.status-modal .clientid-width {
    width: 2%; }
  .modal-dialog.status-modal .status-width {
    width: 2%; }
  .modal-dialog.status-modal .upload-status .status {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.create-img {
    height: 20px ;
}
.filter-img{
    height: 20px ;
}
.creating {
    float: left;
    margin-top: 0%;

}
.filtering {
    display: none;
    float: right;
    margin-top: 0%;
    margin-right:56%;
}
.create {
    margin-top: -4%;
}
.btn {
    margin-top: 10px;
    margin-bottom: 30px;
}
.action-description{
    width: 50% !important;
}
.reduce-width{
    width: 10% !important;
}
.notes-width{
    width: 80% !important;
}

.page {
    display: -webkit-flex;
    display: flex;
    margin-top: 72px;
    height: -webkit-fill-available;
}

.page .sidebar {
    width: 15%;
    background-color: lightgray;
}

.page .page-content {
    width: 85%;
}

/* .history {
     display: none;
} */
.lien-modal .modal-content .modal-body .hide-button {
  display: none; }

thead tr th {
  width: 10%; }

.payment-modal.modal-dialog {
  max-width: 98%; }
  .payment-modal.modal-dialog .modal-content .modal-body {
    padding-left: 0%;
    margin-bottom: -2%; }
    .payment-modal.modal-dialog .modal-content .modal-body .hide-button {
      display: none; }

.payment-section {
  margin-top: -20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: -35px; }
  .payment-section .payment-form {
    width: 100%;
    padding: 10px; }
    .payment-section .payment-form .memo-edit-icon {
      position: absolute;
      top: 10%;
      left: -10%;
      cursor: pointer; }
    .payment-section .payment-form .heading {
      font-weight: bold;
      padding: 10px;
      margin-top: -10px;
      margin-left: -1%; }
    .payment-section .payment-form .bold-text-div {
      font-size: 12px;
      font-weight: bold; }
  .payment-section .payment-documents {
    width: 50%;
    padding: 10px; }
    .payment-section .payment-documents .heading {
      font-weight: bold;
      padding: 10px;
      margin-top: 10px;
      margin-left: -1%; }

.other-payment-section {
  padding: 15px; }

.charges-section .charges-payment-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .charges-section .charges-payment-data .charges-payment-data-left {
    width: 40%;
    padding: 10px; }
  .charges-section .charges-payment-data .charges-payment-data-right {
    width: 50%;
    padding: 10px; }

.charges-section .payment-rejected {
  padding: 0px 10px 0px 10px;
  margin-top: -1%; }

.charges-section .payment-comments .status-comment {
  margin-right: 5px;
  margin-left: 5px; }

.charges-section .approval-comment {
  margin-top: 2%; }

.charges-section .charges-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 10px;
  margin-top: -40px; }
  .charges-section .charges-header > .charges-hoas {
    width: 40%;
    display: -webkit-flex;
    display: flex; }
  .charges-section .charges-header > .charges-hoas Label {
    padding: 5px; }
  .charges-section .charges-header > .charges-date {
    width: 30%;
    display: -webkit-flex;
    display: flex;
    margin-left: 10px; }
  .charges-section .charges-header > .charges-date Label {
    padding: 5px; }
  .charges-section .charges-header > .add-charge button {
    margin: 0;
    width: 200%; }
  .charges-section .charges-header > .add-charge {
    margin-left: 10px; }

.payment-status-label {
  width: 7%;
  padding-left: 1.5%;
  font-size: 86%; }

.checkNumber {
  width: 5%;
  padding-left: 0.5%;
  font-size: 86%; }

.settlementDate {
  width: 6%;
  padding-left: 0.5%;
  font-size: 86%; }

.settlementStatus {
  width: 6%;
  padding-left: 0.5%;
  font-size: 86%; }

.reason {
  width: 4%;
  padding-left: 0.5%;
  font-size: 86%; }

.tracking {
  width: 9%;
  padding-left: 1.5%; }

.documentName-label {
  width: 13%;
  padding-left: 1.5%; }

.payment-approved-input {
  width: 6%; }

.settlementStatus-inputField {
  width: 15%;
  padding-left: 0.5%; }

.reason-inputField {
  width: 14%;
  padding-left: 1%; }

.tracking-inputField {
  width: 28%;
  padding-left: 1.5%; }

.documentNotes-label {
  width: 11%;
  padding-left: 10px; }

.documentNotes-inputField {
  width: 24%;
  padding-left: 1.5%; }

.documentName-inputField {
  padding-left: 0%;
  width: 18%; }

.payment-status-input {
  width: 9%; }

.payment-information-input {
  width: 10%;
  padding-left: 0.5%; }

.payment-information-label {
  width: 180px;
  padding-left: 1.5%; }

.payment-dueDate-label {
  padding-left: 0.5%;
  width: 13%; }

.invoice-number-label {
  width: 5%;
  padding-left: 0.5%; }

.invoice-received-label {
  width: 180px;
  padding-left: 0.5%; }

.invoice-number-input {
  width: 23%;
  padding-left: 0.5%; }

.heading {
  margin-top: -20px;
  font-weight: bold;
  padding: 10px; }

.payment-form-group {
  margin-top: -0.5%; }
  .payment-form-group .payment-div-inv {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 25%;
    padding: 10px; }
    .payment-form-group .payment-div-inv-label {
      margin-right: 10px; }
  .payment-form-group .payment-div-rec {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 25%;
    padding: 10px; }
    .payment-form-group .payment-div-rec-label {
      margin-right: 10px; }
  .payment-form-group .payment-div-due {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 25%;
    padding: 10px; }
    .payment-form-group .payment-div-due-label {
      margin-right: 10px; }
  .payment-form-group .payment-div-num {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 25%;
    padding: 10px; }
    .payment-form-group .payment-div-num-label {
      margin-right: 10px; }
  .payment-form-group .payment-div-date {
    width: 125px; }
  .payment-form-group .payment-div-input {
    width: 50%; }
  @media (max-width: 1250px) {
    .payment-form-group .payment-div-inv {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 25%;
      padding: 6px; }
      .payment-form-group .payment-div-inv-label {
        margin-right: 6px; }
    .payment-form-group .payment-div-rec {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 25%;
      padding: 6px; }
      .payment-form-group .payment-div-rec-label {
        margin-right: 6px; }
    .payment-form-group .payment-div-due {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 25%;
      padding: 6px; }
      .payment-form-group .payment-div-due-label {
        margin-right: 6px; }
    .payment-form-group .payment-div-num {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 25%;
      padding: 6px; }
      .payment-form-group .payment-div-num-label {
        margin-right: 6px; }
    .payment-form-group .payment-div-date {
      width: 125px; }
    .payment-form-group .payment-div-input {
      width: 50%; } }

.payment-info {
  margin-right: 5px;
  margin-left: 10px;
  display: -webkit-flex;
  display: flex; }
  .payment-info .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 20%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .payment-info .question .radio .custom-control {
        margin-right: 0px; }
    .payment-info .question .text {
      text-align: end; }
  .payment-info .payee {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 42%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .payee label {
      margin-right: 10px; }
    .payment-info .payee .select-box {
      width: 400px; }
  .payment-info .other-payee {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 42%;
    margin-left: 10px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .other-payee label {
      margin-right: 10px;
      width: 160px;
      text-align: end; }
    .payment-info .other-payee .select-box {
      width: 400px; }
  .payment-info .memo {
    width: 35%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: -webkit-inline-flex;
    display: inline-flex; }
    .payment-info .memo-head {
      font-weight: 600;
      padding-right: 10px;
      width: 180px;
      text-align: end; }
    .payment-info .memo-details {
      width: 20em; }

.settlement-select {
  height: 35; }

.paymentTable {
  margin-top: -2%; }

.documentClass {
  padding-left: 2%;
  margin-bottom: 1%; }

.checkNumber-input {
  width: 12%;
  font-size: 90%; }

.documentUploadLabel {
  width: 15%;
  padding-left: 21px; }

.documentUploadInput {
  width: 18%; }

.documentTab {
  width: 50%; }

.noDocument {
  margin-top: 20px;
  margin-left: 10px; }

.other-acc {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 10px; }
  .other-acc .acc {
    display: -webkit-flex;
    display: flex; }
    .other-acc .acc .text {
      width: 19em; }
  .other-acc label {
    margin-right: 10px; }

.edit-address .modal-content {
  width: 150%; }

.code {
  margin-bottom: 20px; }

.aco {
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px; }

.questions .question {
  margin: 10px; }

.questions .radio-buttons {
  display: -webkit-flex;
  display: flex; }

.access-codes-container {
  width: 50%; }

.custom-control {
  margin-right: 10px;
  margin-left: 10px; }

.access-code-modal.modal-dialog {
  max-width: 85%; }
  .access-code-modal.modal-dialog .modal-content {
    width: 100%;
    padding-left: 0%; }
  .access-code-modal.modal-dialog .control-details {
    padding: 10px; }
  .access-code-modal.modal-dialog .yes-no-div {
    display: block;
    margin-bottom: 10px; }
    .access-code-modal.modal-dialog .yes-no-div .yes-no {
      display: -webkit-flex;
      display: flex; }
  .access-code-modal.modal-dialog .access-form {
    position: relative;
    bottom: 60px;
    width: 45%;
    left: 20%; }
  .access-code-modal.modal-dialog .create-Btn {
    position: absolute;
    bottom: 16px;
    left: 125px; }

.tab-content {
  padding: 20px 0px 0px 0px; }

.nav-tabs {
  border-bottom: none !important; }

.tab li.nav-item {
  background-color: darkgrey;
  min-width: 15.6%;
  text-align: center; }
  .tab li.nav-item.active {
    margin-top: -0.5%;
    background-color: lightblue;
    border: none; }
  .tab li.nav-item .nav-link {
    color: black;
    display: block;
    padding: 0.5rem 0.5rem; }

.tab li.nav-item .nav-link.active {
  background-color: lightblue;
  border-color: lightblue; }

.general-info-tab {
  border-bottom: 2px solid #dee2e6 !important; }
  .general-info-tab .general-info-tabnavitem {
    margin-bottom: -2px !important;
    cursor: pointer; }
    .general-info-tab .general-info-tabnavitem .general-info-tabnavlink.active {
      color: #495057;
      background-color: #fff;
      border-bottom: 4px solid lightblue;
      border-top: none;
      border-right: none;
      border-left: none; }
    .general-info-tab .general-info-tabnavitem .nav-link {
      color: black;
      display: block;
      padding: 0.5rem 0.5rem; }

.modalTabs {
  margin-left: 2%; }
  .modalTabs .nav-item {
    width: 45%;
    text-align: center; }
    .modalTabs .nav-item.active {
      margin-top: -0.5%;
      background-color: lightblue;
      border: none; }

.general-info-tabpage-content {
  padding: 20px 10px 0px 0px; }

.form-modal.modal-dialog {
  max-width: 95%; }

.hoaForm.modal-dialog {
  max-width: 95%; }

.add-management-company {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .add-management-company .add-model-left-portion {
    width: 55%;
    padding: 10px; }
  .add-management-company .add-model-right-portion {
    width: 45%;
    padding: 10px; }
    .add-management-company .add-model-right-portion .note {
      font-size: 10px; }
    .add-management-company .add-model-right-portion .add-poc {
      color: white;
      display: -webkit-flex;
      display: flex;
      border: 1px solid white;
      background: #53bf53;
      border-radius: 20px;
      width: 20%;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      cursor: pointer; }
      .add-management-company .add-model-right-portion .add-poc .text {
        padding: 8px; }

.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px; }

.add_assessment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .add_assessment .fy_assessment {
    border: 1px solid lightgrey;
    padding: 10px;
    width: 48%;
    margin: 8px; }

.add_fy_Assesment {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 20%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  height: 0%; }
  .add_fy_Assesment .text {
    padding: 8px; }

.address-body {
  border: 1px solid lightgray;
  padding: 15px 10px 0px 10px;
  margin: 10px 0px 0px; }

.address-name {
  border-radius: 0px 15px 15px 0px !important; }

.rdg-editor-container {
  z-index: 10051 !important; }

.add_New_Assessment {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 13%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 8px;
  cursor: pointer; }

.add_New_Assessment.disable {
  pointer-events: none;
  opacity: 0.5; }

.assessment_type {
  font-weight: bold;
  margin-top: 1%;
  font-size: 17px;
  color: #ff8f00c4; }

.add_line_item {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #6c757d;
  border-radius: 20px;
  width: 200%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: -2%;
  margin-left: 85%;
  padding: 8px; }

.table_allignment {
  margin-top: 1%; }

.new_assessment {
  margin-top: 1%;
  min-width: 30%;
  margin-right: 10px; }

.add_address {
  display: -webkit-flex;
  display: flex; }

.add-charge {
  margin-left: 80%;
  margin-bottom: -30px; }

.btn-save-charge {
  margin-left: -40%;
  margin-top: 11px; }

.form-bordered {
  border: 1px solid #ccc;
  padding: 15px 25px;
  margin-bottom: 20px;
  margin-top: 20px; }

.assessment-input {
  width: 50%;
  margin-left: -30px;
  margin-top: 8px; }

.assessment-input-select {
  width: 30%;
  margin-left: -60px;
  margin-top: 8px;
  margin-right: -50px; }

.add-charge-button {
  margin-right: 20px; }

.assessment-delete-button {
  font-size: 14px;
  margin-left: 2px;
  cursor: pointer;
  margin-top: 14px; }

.warning-modal .modal-content {
  width: 105%; }

.form-date {
    float: left;
    margin-top: -10%;
}
.to-date {
    float: right;
    margin-top: -6%;
    margin-right:50%
}
.dates {
    margin-top: 1%;
}
.react-bootstrap-table {
    margin-top: 4%;
}
.form-date {
    float: left;
    margin-top: -10%;
}
.to-date {
    float: right;
    margin-top: -6%;
    margin-right:50%
}

.react-bootstrap-table {
    margin-top: 4%;
}
.general-info-tabcol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .general-info-tabcol .listCompButton {
    float: left;
    width: 100%; }
    .general-info-tabcol .listCompButton button {
      float: left; }
  .general-info-tabcol .details {
    padding: 10px;
    width: 100%; }
    .general-info-tabcol .details .message {
      text-align: center;
      margin-top: 24px; }
    .general-info-tabcol .details .heading {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: crimson;
      margin-bottom: 20px; }
    .general-info-tabcol .details .buttons {
      display: -webkit-flex;
      display: flex;
      margin-bottom: 10px; }
      .general-info-tabcol .details .buttons button {
        border-radius: 4px;
        width: 70px;
        height: 35px;
        border: none; }
      .general-info-tabcol .details .buttons .update-btn button {
        background: #0e7bd8;
        color: white; }
      .general-info-tabcol .details .buttons .delete-btn button {
        color: white;
        background: #ea2f2f; }
    .general-info-tabcol .details .edit-btn {
      border-radius: 4px;
      width: 70px;
      height: 35px;
      border: none;
      background-color: gray; }
    .general-info-tabcol .details .info {
      padding: 5px 5px 5px 5px;
      display: -webkit-flex;
      display: flex; }
      .general-info-tabcol .details .info .name {
        font-size: 12px;
        font-weight: 600; }
      .general-info-tabcol .details .info .value {
        font-size: 14px;
        margin-left: 5px; }
    .general-info-tabcol .details .create-btn {
      position: relative;
      float: right;
      left: -5%; }
      .general-info-tabcol .details .create-btn button {
        border-radius: 4px;
        width: 70px;
        height: 35px;
        border: none;
        color: white;
        background: #118709; }
    .general-info-tabcol .details .accesscodes {
      border-bottom: 4px solid powderblue; }
      .general-info-tabcol .details .accesscodes .buttons {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .general-info-tabcol .details .accesscodes .buttons .create-btn {
          margin-right: 5px;
          position: absolute;
          top: 20px;
          right: 30px; }
    .general-info-tabcol .details .lease-info {
      border-bottom: 4px solid powderblue; }
      .general-info-tabcol .details .lease-info .buttons {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .general-info-tabcol .details .lease-info .buttons .create-btn {
          margin-right: 5px;
          position: absolute;
          top: 20px;
          right: 30px; }
    .general-info-tabcol .details .property-hoa-list {
      border-bottom: 4px solid powderblue; }

.listCompButton {
  position: relative;
  float: right;
  margin-top: -1%;
  margin-right: 2%;
  width: 65%; }
  .listCompButton button {
    float: right; }

.addMarginBottom {
  margin-bottom: 1%; }

.paginationPageSelect {
  /*
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;*/
  width: 22% !important;
  margin-right: 5%;
  margin-left: 4%; }

.hoa-details .increase-width {
  width: 40%; }

.hoa-details .mutli-select .hoa-multi-select {
  display: -webkit-flex;
  display: flex;
  float: right;
  margin-bottom: 10px;
  width: 100%; }
  .hoa-details .mutli-select .hoa-multi-select .select-box {
    width: 75%; }
  .hoa-details .mutli-select .hoa-multi-select .button {
    padding: 7px; }
    .hoa-details .mutli-select .hoa-multi-select .button button {
      border-radius: 4px;
      width: 70px;
      height: 35px;
      border: none;
      color: white;
      background: gray; }

.create-hoa {
  width: 130px; }

.edit-link {
  cursor: pointer; }

.acc-number {
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  margin-top: 2%; }

.hoa_website {
  display: -webkit-flex;
  display: flex;
  overflow-wrap: break-word; }

.hoa_site {
  width: 30%;
  font-weight: bold; }

.assesment_description {
  width: 30%;
  font-weight: bold; }

.payment_site {
  width: 30%;
  font-weight: bold; }

.account_name {
  width: 30%;
  font-weight: bold; }

.account_password {
  width: 30%;
  font-weight: bold; }

.address {
  margin-top: 2%; }

.listdiv .lease-info .buttons .update-btn {
  position: absolute;
  top: 243px;
  right: 2%; }

.lease_info_modal.modal-dialog {
  min-width: 50% !important; }
  .lease_info_modal.modal-dialog .modal-body {
    padding-left: 20px; }

.create-btn {
    height: 40px;
    left: 2%;
    position: absolute;
}
.details{
    margin-top: 1%
}
.tac {
	text-align: center;
}

.inspections-info {
  width: 55%; }
  .inspections-info button {
    float: right;
    position: relative;
    bottom: 45px;
    left: 15%;
    border-radius: 4px;
    width: 70px;
    height: 35px;
    border: none;
    color: white;
    background: gray; }

.inspections-intervals .intervals-info {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 15px; }
  .inspections-intervals .intervals-info button {
    position: relative;
    left: 2%;
    top: 5px;
    border-radius: 4px;
    width: 70px;
    height: 35px;
    border: none;
    color: white;
    background: gray; }
  .inspections-intervals .intervals-info .heading {
    padding: 10px;
    width: 25%; }
  .inspections-intervals .intervals-info .select-box {
    width: 30%; }

.tenantForm input[type="text"],
.tenantForm input[type="number"] {
  width: 60%; }

.tenantForm label {
  margin-right: 3%; }

.tenantForm .styled,
.tenantForm input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0 2%;
  width: 12px;
  height: 12px;
  background: #eeeeee;
  box-shadow: inset 0 0 0 .4em white, 0 0 0 .1em;
  border-radius: 50%;
  -webkit-transition: .2s;
  transition: .2s;
  cursor: pointer;
  color: #363945; }
  .tenantForm .styled:hover, .tenantForm .styled:checked,
  .tenantForm input[type="radio"]:hover,
  .tenantForm input[type="radio"]:checked {
    background: #363945;
    box-shadow: inset 0 0 0 .6em white, 0 0 0 .2em; }
  .tenantForm .styled:checked,
  .tenantForm input[type="radio"]:checked {
    background: #56be8e;
    box-shadow: inset 0 0 0 .4em white, 0 0 0 .3em #56be8e; }
  .tenantForm .styled:focus,
  .tenantForm input[type="radio"]:focus {
    outline: 0; }

.back {
  position: absolute;
  border: none;
  border-radius: 10px;
  width: 4rem;
  height: 30px;
  top: -4px;
  /* color: white; */
  background: gray; }
  .back a {
    color: white; }
  .back a:hover {
    color: white;
    text-decoration: none; }

.property-manage {
  display: -webkit-flex;
  display: flex;
  margin-right: 15px;
  margin-bottom: 10px; }
  .property-manage .page-title {
    width: 35%;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 10px; }
    .property-manage .page-title .page-heading {
      margin-bottom: 15px;
      margin-top: 15px; }
  .property-manage .property-info-table {
    width: 65%; }
    .property-manage .property-info-table .property-details .react-bootstrap-table {
      margin: 0; }
    .property-manage .property-info-table .property-details > div button {
      position: absolute;
      top: -15px;
      right: 20px; }
  .property-manage .property-details .property-info {
    float: right;
    text-align: right;
    font-size: 12px;
    font-weight: 600; }
    .property-manage .property-details .property-info > div {
      line-height: 12px; }
    .property-manage .property-details .property-info .green {
      font-size: 15px;
      color: green;
      margin-top: 5px; }
    .property-manage .property-details .property-info .red {
      font-size: 15px;
      color: red;
      margin-top: 5px; }

.users {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .users .page-title {
    font-size: 2rem;
    font-weight: 600; }

.quick-stats {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid lightblue; }
  .quick-stats .heading {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px; }
  .quick-stats .section {
    display: -webkit-flex;
    display: flex;
    padding-bottom: 10px; }
    .quick-stats .section .properties {
      width: 18%; }
    .quick-stats .section .properties-av {
      width: 20%;
      border-left: 1px solid lightblue;
      border-right: 1px solid lightblue;
      text-align: center; }
    .quick-stats .section .state-filter {
      width: 60%; }
      .quick-stats .section .state-filter .dropdown {
        padding: 15px;
        width: 75%;
        margin: 0px auto; }
        .quick-stats .section .state-filter .dropdown .states-select {
          width: 100%; }
      .quick-stats .section .state-filter .results {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
                justify-content: space-around; }
  .quick-stats .key-value {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .quick-stats .key-value .key {
      font-size: 16px;
      font-weight: 600; }
    .quick-stats .key-value .value {
      font-size: 20px;
      font-weight: bold; }

.graph-data {
  padding-top: 20px; }
  .graph-data .form {
    margin-left: 10%; }

.reduce-width {
  width: 10% !important;
  padding: 0.5 rem; }

.notes-width {
  width: 80% !important; }

.address-width {
  width: 15%; }

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret,
.react-bootstrap-table th .order > .dropdown > .caret,
.react-bootstrap-table th .order > .dropup > .caret {
  margin-bottom: 0;
  margin-top: 0; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup .caret {
  border-bottom: 4px dashed;
  border-top: 0;
  content: ''; }

.filter-input {
	width: 250px;
}

.search-btn {
	margin-top: 25px;
    margin-left: 10px;
}

.btn-group.actions {
	margin-top: -7%!important;
}
.sidebar-section {
    height: 100%;
    width: 17%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 10px;
}

.sidebar-section .sidebar {
    width: 229px;
    position: fixed;
    left: 0;
    padding: 102px 0rem .5rem;
    height: 100%;
    overflow: hidden;
    z-index: 19;
    background-color: black;
}

.sidebar-section .sidebar .scrollbar-inner {
    position: relative;
    height: 100%;

}

.sidebar-section .sidebar .scrollbar-inner .customDropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button svg {
    margin-left: 45%;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content {
    display: none;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
}


.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content>.link {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .unhide {
    display: block;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown:hover .button {
    background-color: #818181;
}


.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content>.link:hover {
    background-color: #ddd;
}


.sidebar-section .sidebar .scrollbar-inner .navigation {
    padding: 0;
    list-style: none;
}

.sidebar-section .sidebar .scrollbar-inner .navigation a:hover {
    text-decoration: none;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item a {
    text-decoration: none;
    color: white;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item:hover {
    text-decoration: none !important;
    background-color: #818181 !important;
    cursor: pointer;
}

.list-item {
    color: white;
    background-color: #020202;
}

.react-bootstrap-table .table {
    table-layout: auto !important;
}

.react-bootstrap-table {
    font-size: 12px !important;
}

.btn-group.actions {
    position: relative;
    float: right;
    margin-top: -4%;
    margin-bottom: 1%;
    right: 0%;
}
.btn-group.addactions {
    position: relative;
    float: right;
    margin-top: -65px;
    margin-bottom: 1%;
    right: 30px;
    width: 75px;
}
.btn-group.actions button {
    border-radius: 5px !important;
    margin-right: 10px;
}

.table-actions button {
    margin-right: 5px;
}

input[type="file"] {
    height: 58px;
}
.email-access-general-info .page-header {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  min-height: 70px;
  padding: 0.5rem 2rem;
  z-index: 20;
  display: -ms-flexbox;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15); }

.email-access-general-info .property-data {
  margin-top: 75px;
  padding: 1rem 2rem;
  text-align: right; }

.email-access-general-info .general-component {
  padding: 0rem 1rem; }
  .email-access-general-info .general-component .listdiv .lease-info .buttons .update-btn {
    position: absolute;
    top: 280px;
    right: 2%; }
  .email-access-general-info .general-component .reduce-margin {
    margin: 0px; }
  .email-access-general-info .general-component .general-info-tab {
    border: none !important; }
  .email-access-general-info .general-component .general-info-tab li.nav-item {
    background-color: darkgrey;
    min-width: 14.1%;
    text-align: center; }
    .email-access-general-info .general-component .general-info-tab li.nav-item.active {
      margin-top: -0.5%;
      background-color: lightblue;
      border: none; }
    .email-access-general-info .general-component .general-info-tab li.nav-item .nav-link {
      color: black; }
  .email-access-general-info .general-component .general-info-tab li.nav-item .nav-link.active {
    background-color: lightblue;
    border-color: lightblue;
    border: none !important; }

