.filter-input {
	width: 250px;
}

.search-btn {
	margin-top: 25px;
    margin-left: 10px;
}

.btn-group.actions {
	margin-top: -7%!important;
}