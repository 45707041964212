.create-btn {
    height: 40px;
    left: 2%;
    position: absolute;
}
.details{
    margin-top: 1%
}
.tac {
	text-align: center;
}
